﻿html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, p, td, strong {
    padding: 0;
    margin: 0;
    font-family: "Source Han Sans CN","Source Han Serif SC";
    font-size: 14px;
}
body, html, .index-wrap {
	width: 100%;
}
.index-wrap {
	min-width: 1200px;
	background: url("../images/index_bg.jpg") no-repeat center;
}
.index {
	width: 1200px;
	margin: 0 auto;
	padding-top: 50px;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}
.index .banner {
	width: 1200px;
	height: 251px;
	padding-top: 607px;
	margin-top: 20px;
	opacity: 0.2;
}
.index .banner .download-wrap {
	width: 480px;
	height: 162px;
	margin: 0 auto;
	padding: 23px 22px 0;
	background: url("../images/download_border.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.d-logo-l, .d-code-r, .d-logo-l img {
	width: 138px;
	height: 138px;
}
.d-logo-l, .download-box {
	float: left;
}
.d-code-r {
	position: relative;
	float: right;
}
.d-code-r img {
	display: block;
	width: 100%;
}
.d-code-r span {
	position: absolute;
	top: 0;
	right: -10px;
	width: 155px;
	height: 20px;
	background: url("../images/scanning.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	-webkit-animation: scanning 4s ease-in-out infinite;
	-moz-animation: scanning 4s ease-in-out infinite;
	-o-animation: scanning 4s ease-in-out infinite;
	animation: scanning 4s ease-in-out infinite;
}
@-webkit-keyframes 'scanning' {
	0% {-webkit-transform: translateY(-3px);
		-moz-transform: translateY(-3px);
		-ms-transform: translateY(-3px);
		-o-transform: translateY(-3px);}
	50% {-webkit-transform: translateY(115px);
		-moz-transform: translateY(115px);
		-ms-transform: translateY(115px);
		-o-transform: translateY(115px;)}
}
.d-logo-l img {
	-webkit-animation: sport4 3s linear infinite;
}
@-webkit-keyframes'sport4' {
	0 {-webkit-transform: translateY(0rem);}
	5% {-webkit-transform: translateY(-0.4rem);}
	10% {-webkit-transform: translateY(0rem);}
	15% {-webkit-transform: translateY(-0.2rem);}
	20% {-webkit-transform: translateY(0rem);}
}
.download-box {
	width: 188px;
	height: 142px;
	margin-left: 9px;
	background: url("../images/download_btn.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.download-box a {
	float: left;
	width: 188px;
	height: 47px;
	background: #fff;
	opacity: 0.1;
	filter:alpha(opacity=10);
}
.download-box a:hover {
	opacity: 0;
	filter:alpha(opacity=0);
}
.index .banner .banner-pos {
	position: relative;
}
.index .banner .banner-pos .moon, .banner .banner-pos h1, .title-h1 , .role-lf, .role-voice, .logo, .role-yg, .role-hsl, .shadow-yg, .shadow-hsl, .cloud-wrap {
	position: absolute;
}
.cloud-wrap .cloud-01 {
	position: absolute;
	top: -113px;
	left: -213px;
	width: 169px;
	height: 45px;
	-webkit-animation: Beat 1.2s ease-in-out infinite;
	-moz-animation: Beat 1.2s ease-in-out infinite;
	-ms-animation: Beat 1.2s ease-in-out infinite;
	-o-animation: Beat 1.2s ease-in-out infinite;
	animation: Beat 1.2s ease-in-out infinite;
}
.cloud-wrap .cloud-02 {
	position: absolute;
	top: -270px;
    left: 950px;
	width: 246px;
	height: 82px;
	-webkit-animation: Beat 2.5s ease-in-out infinite;
	-moz-animation: Beat 2.5s ease-in-out infinite;
	-ms-animation: Beat 2.5s ease-in-out infinite;
	-o-animation: Beat 2.5s ease-in-out infinite;
	animation: Beat 2.5s ease-in-out infinite;
}
.moon {
	top: -1047px;
	left: 276px;
	width: 720px;
	height: 720px;
	z-index: 9988;
	-webkit-animation: rotate 150s infinite linear;
	-moz-animation: rotate 150s infinite linear;
	-ms-animation: rotate 150s infinite linear;
	-o-animation: rotate 150s infinite linear;
	animation: rotate 150s infinite linear;
}
@-webkit-keyframes rotate {
	0%{-webkit-transform:rotate(0deg);}
	100%{-webkit-transform:rotate(360deg);}
}
.banner .banner-pos h1 {
	position: absolute;
	top: -444px;
	left: 201px;
}
.banner .title-h1 {
	top: -20px;
	left: 0;
	width: 730px;
	height: 373px;
	z-index: 9990;
}
.banner .title-p {
	display: none;
	position: absolute;
	top: 290px;
	left: 270px;
	width: 414px;
	height: 80px;
	background: url("../images/title_p.png") no-repeat;
	background-size: 100% 100%;
	z-index: 9990;
	-webkit-animation: sway 3s linear infinite;
	-moz-animation: sway 3s linear infinite;
	-ms-animation: sway 3s linear infinite;
	-o-animation: sway 3s linear infinite;
	animation: sway 3s linear infinite;
}
.banner .role-lf {
	top: -90px;
	left: 540px;
	width: 229px;
	height: 277px;
	z-index: 9989;
	-webkit-animation: Beat 3s ease-in-out infinite;
	-moz-animation: Beat 3s ease-in-out infinite;
	-ms-animation: Beat 3s ease-in-out infinite;
	-o-animation: Beat 3s ease-in-out infinite;
	-animation: Beat 3s ease-in-out infinite;
}
.banner .role-voice {
	top: -76px;
	left: 710px;
	width: 147px;
	height: 131px;
	-webkit-animation: sway 2s linear infinite;
	-moz-animation: sway 2s linear infinite;
	-ms-animation: sway 2s linear infinite;
	-o-animation: sway 2s linear infinite;
	animation: sway 2s linear infinite;
}
@-webkit-keyframes'sway' {
	0 {-webkit-transform: rotateZ(15deg) scale(1);
		-moz-transform: rotateZ(15deg) scale(1);
		-ms-transform: rotateZ(15deg) scale(1);
		-o-transform: rotateZ(15deg) scale(1);
		transform: rotateZ(15deg) scale(1);}
	50% {-webkit-transform: rotateZ(-1deg) scale(0.98);
		-moz-transform: rotateZ(-1deg) scale(0.98);
		-ms-transform: rotateZ(-1deg) scale(0.98);
		-o-transform: rotateZ(-1deg) scale(0.98);
		transform: rotateZ(-1deg) scale(0.98);}
}
.banner .logo {
	top: -560px;
	left: -85px;
	width: 271px;
}
@media all and (max-width: 1300px) {
	.banner .logo {
		left: 0px;
	}
}
@media all and (max-width: 1200px) {
	.index {
		overflow: hidden;
	}
}
.banner .role-yg {
	top: -201px;
	left: 32px;
	width: 256px;
	height: 326px;
	z-index: 9990;
	-webkit-animation: sway 2s linear infinite;
	-moz-animation: sway 2s linear infinite;
	-ms-animation: sway 2s linear infinite;
	-o-animation: sway 2s linear infinite;
	animation: sway 2s linear infinite;
}
.banner .role-hsl {
	top: -221px;
	left: 867px;
	width: 250px;
	height: 344px;
	z-index: 9990;
	-webkit-animation: sway2 2s linear infinite;
	-moz-animation: sway2 2s linear infinite;
	-ms-animation: sway2 2s linear infinite;
	-o-animation: sway2 2s linear infinite;
	animation: sway2 2s linear infinite;
}
.banner .shadow-yg {
	top: 104px;
	left: -79px;
	width: 277px;
	height: 106px;
	z-index: 9989;
	-webkit-animation: sway 2s linear infinite;
	-moz-animation: sway 2s linear infinite;
	-ms-animation: sway 2s linear infinite;
	-o-animation: sway 2s linear infinite;
	animation: sway 2s linear infinite;
}
.banner .shadow-hsl {
	top: 101px;
	left: 880px;
	width: 336px;
	height: 165px;
	z-index: 9989;
	-webkit-animation: sway2 2s linear infinite;
	-moz-animation: sway2 2s linear infinite;
	-ms-animation: sway2 2s linear infinite;
	-o-animation: sway2 2s linear infinite;
	animation: sway2 2s linear infinite;
}
@-webkit-keyframes'sway2' {
	0 {-webkit-transform: rotateZ(-1deg) scale(0.98);
		-moz-transform: rotateZ(-1deg) scale(0.98);
		-ms-transform: rotateZ(-1deg) scale(0.98);
		-o-transform: rotateZ(-1deg) scale(0.98);
		transform: rotateZ(-1deg) scale(0.98);}
	50% {-webkit-transform: rotateZ(1deg) scale(1);
		-moz-transform: rotateZ(1deg) scale(1);
		-ms-transform: rotateZ(1deg) scale(1);
		-o-transform: rotateZ(1deg) scale(1);
		transform: rotateZ(1deg) scale(1);}
}
.main {
	width: 1200px;
	height: 891px;
}
.main .data-wrap {
	float: left;
	width: 630px;
	height: 496px;
}
.main .video-wrap {
	float: right;
	width: 542px;
	height: 496px;
}
.main .checkpoint-wrap {
	float: left;
	width: 1200px;
	height: 380px;
}
.main div h2 {
	width: 240px;
	height: 55px;
	margin-bottom: 7px;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-ms-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
}
.main div h2:hover {
	-webkit-animation: Beat 1.5s ease-in-out infinite;
	-moz-animation: Beat 1.5s ease-in-out infinite;
	-ms-animation: Beat 1.5s ease-in-out infinite;
	-o-animation: Beat 1.5s ease-in-out infinite;
	animation: Beat 1.5s ease-in-out infinite;
}
.main div h2 a {
	display: block;
	width: 240px;
	height: 55px;
}
.main div .data-tit {
	background: url("../images/tit_h2.png") no-repeat 0 0;
	background-size: 200% 300%;
	-webkit-background-size: 200% 300%;
	-moz-background-size: 200% 300%;
	-ms-background-size: 200% 300%;
	-o-background-size: 200% 300%;
}
.main div .video-tit {
	background: url("../images/tit_h2.png") no-repeat 0 -55px;
	background-size: 200% 300%;
	-webkit-background-size: 200% 300%;
	-moz-background-size: 200% 300%;
	-ms-background-size: 200% 300%;
	-o-background-size: 200% 300%;
}
.main div .checkpoint-tit {
	background: url("../images/tit_h2.png") no-repeat 0 -110px;
	background-size: 200% 300%;
	-webkit-background-size: 200% 300%;
	-moz-background-size: 200% 300%;
	-ms-background-size: 200% 300%;
	-o-background-size: 200% 300%;
}
.main div .data-tit:hover {
	background: url("../images/tit_h2.png") no-repeat -240px 0;
	background-size: 200% 300%;
	-webkit-background-size: 200% 300%;
	-moz-background-size: 200% 300%;
	-ms-background-size: 200% 300%;
	-o-background-size: 200% 300%;
}
.main div .video-tit:hover {
	background: url("../images/tit_h2.png") no-repeat -240px -55px;
	background-size: 200% 300%;
	-webkit-background-size: 200% 300%;
	-moz-background-size: 200% 300%;
	-ms-background-size: 200% 300%;
	-o-background-size: 200% 300%;
}
.main div .checkpoint-tit:hover {
	background: url("../images/tit_h2.png") no-repeat -240px -110px;
	background-size: 200% 300%;
	-webkit-background-size: 200% 300%;
	-moz-background-size: 200% 300%;
	-ms-background-size: 200% 300%;
	-o-background-size: 200% 300%;
}
.main .data-wrap {
	-webkit-perspective: 1800;
}
.main .data-wrap img {
	width: 631px;
	height: 411px;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}
.main .data-wrap img:hover {
	margin-top: -10px;
	-webkit-transform: rotateY(-5deg);
	-moz-transform: rotateY(-5deg);
	-ms-transform: rotateY(-5deg);
	-o-transform: rotateY(-5deg);
}
@-webkit-keyframes'Beat' {
	0 {-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);}
	50% {-webkit-transform: translateY(5px);
		-moz-transform: translateY(5px);
		-ms-transform: translateY(5px);
		-o-transform: translateY(5px);
		transform: translateY(5px);}
}
@-webkit-keyframes'Beat3' {
	0 {-webkit-transform: translateY(0);
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);}
	50% {-webkit-transform: translateY(15px);
		-moz-transform: translateY(15px);
		-ms-transform: translateY(15px);
		-o-transform: translateY(15px);
		transform: translateY(15px);}
}
.slideBox { 
	overflow: hidden; 
	position: relative;   
	width: 542px; 
	height: 410px; 
	background: url("../images/video_shadow.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	cursor: pointer;
}
.slideBox .hd {
	position:absolute;
	right: 200px;
	bottom: 30px;
	height: 15px;
	z-index: 1;
}
.slideBox .hd ul {
	float:left;
	zoom:1;
}
.slideBox .hd ul li {
	float:left;
	margin: 0 15px;
	width:18px;
	height:18px;
	border: 1px solid #030000;
	border-radius: 50%;
	line-height:14px;
	text-align:center;
	background:#fff;
	cursor:pointer;
}
.slideBox .hd ul li.on {
	background:#4d4d4d;
}
.slideBox .bd{
	position:relative;
	height:100%;
	z-index:0;
}
.slideBox .bd li { 
	position: relative;
	display:block;  
	width: 502px; 
	height: 384px;
	margin: 12px auto 0;
	zoom:1; 
	vertical-align:middle;
}
.slideBox .bd li span {
	position: absolute;
	top: 0;
	left: 0;
	width: 502px; 
	height: 384px;
	background: rgba(0, 0, 0, 0.5);
}
.slideBox .bd li i {
	position: absolute;
	top: 135px;
	left: 188px;
	width: 109px; 
	height: 109px;
	background: url("../images/video_play.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	-webkit-animation: Beat2 2.1s ease-in-out infinite;
	-moz-animation: Beat2 2.1s ease-in-out infinite;
	-ms-animation: Beat2 2.1s ease-in-out infinite;
	-o-animation: Beat2 2.1s ease-in-out infinite;
	animation: Beat2 2.1s ease-in-out infinite;
}
@-webkit-keyframes'Beat2' {
	0 {-webkit-transform: scale(1); 
		-moz-transform: scale(1); 
		-ms-transform: scale(1); 
		-o-transform: scale(1); 
		transform: scale(1); 
		opacity: 1; 
		filter:alpha(opacity=100);}
	50% {-webkit-transform: scale(1.1); 
		-moz-transform: scale(1.1); 
		-ms-transform: scale(1.1); 
		-o-transform: scale(1.1); 
		transform: scale(1.1); 
		opacity: 0.7; 
		filter:alpha(opacity=70);}
}
.slideBox .bd img { 
	display:block;  
	width: 502px; 
	height: 384px;
}
.checkpoint-wrap div {
	width: 1200px;
	height: 264px;
}
.checkpoint-wrap div ul li {
	position: relative;
	float: left;
	width: 300px;
	height: 264px;
	-webkit-transform: rotate(0.3deg);
	-moz-transform: rotate(0.3deg);
	-ms-transform: rotate(0.3deg);
	-o-transform: rotate(0.3deg);
}
.checkpoint-wrap .mus-01 {
	background: url("../images/checkpoint_01.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.checkpoint-wrap .mus-02 {
	background: url("../images/checkpoint_02.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.checkpoint-wrap .mus-03 {
	background: url("../images/checkpoint_03.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.checkpoint-wrap .mus-04 {
	background: url("../images/checkpoint_04.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.scale-big {
	z-index: 9999;
	-webkit-animation: scaleBig 1s alternate forwards;
	-moz-animation: scaleBig 1s alternate forwards;
	-ms-animation: scaleBig 1s alternate forwards;
	-o-animation: scaleBig 1s alternate forwards;
	animation: scaleBig 1s alternate forwards;
}
@-webkit-keyframes'scaleBig' {
	0 {-webkit-transform: scale(1) rotate(0deg);
		-moz-transform: scale(1) rotate(0deg);
		-ms-transform: scale(1) rotate(0deg);
		-o-transform: scale(1) rotate(0deg);
		transform: scale(1) rotate(0deg);}
	100% {-webkit-transform: scale(1.15) rotate(2deg);
		-moz-transform: scale(1.15) rotate(2deg);
		-ms-transform: scale(1.15) rotate(2deg);
		-o-transform: scale(1.15) rotate(2deg);
		transform: scale(1.15) rotate(2deg);}
}
.checkpoint-wrap div ul li a {
	position: absolute;
	top: 215px;
	right: 90px;
	width: 31px;
	height: 31px;
	cursor: pointer;
}
.checkpoint-wrap div ul li .play {
	background: url("../images/voice_01.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.checkpoint-wrap div ul li .voice {
	background: url("../images/voice_02.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	-webkit-animation: Beat2 2.1s ease-in-out infinite;
	-moz-animation: Beat2 2.1s ease-in-out infinite;
	-ms-animation: Beat2 2.1s ease-in-out infinite;
	-o-animation: Beat2 2.1s ease-in-out infinite;
	animation: Beat2 2.1s ease-in-out infinite;
}
.footer {
	width: 100%;
	min-width: 1200px;
	height: 133px;
	padding-top: 31px;
	background: #000;
}
.footer .footer-box {
	width: 1200px;
	height: 133px;
	margin: 0 auto;
}
.footer dl {
	float: right;
	width: 930px;
}
.footer dl dt, .footer dl dt img {
	float: left;
	width: 140px;
	height: 39px;
}
.footer dl dt {
	margin-top: 20px;
}
.footer dl dd {
	float: right;
	width: 735px;
	color: #b2b2b2;
	font-size: 12px;
	text-align: left;
}
.footer dl dd p {
	overflow: hidden;
	bottom: 0;
	line-height: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.footer dl dd p a {
	margin: 0px 8px;
	color: #b2b2b2;
}
.footer dl dd p a:hover {
	color: #fff;
	cursor: pointer;
}
.footer .cooperation {
	float: left;
	width: 210px;
	height: 65px;
	padding-top: 34px;
	margin: -9px 0 0 46px;
}
.footer .cooperation .coop-box {
	position: relative;
	width: 172px;
	height: 29px;
	padding-top: 3px;
	background: #b5b5b5;
	color: #333;
	font-size: 12px;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	transition: all 1s;
}
.footer .cooperation .coop-box:hover {
	background: #fff;
	cursor: pointer;
}
.footer .cooperation .coop-box span {
	float: left;
	width: 127px;
	border-right: 1px solid #8e8e8e;
	line-height: 27px;
	text-align: center;
	padding-right: 10px;
}
.footer .cooperation .coop-box img {
	width: 12px;
	height: 8px;
	margin: 10px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.footer .coop-all {
	display: none;
	position: absolute;
	bottom: 32px;
	left: 0px;
	z-index: 9999;
}
.footer .coop-all {
	overflow: auto;
	width: 172px;
	height: 300px;
}
.footer .coop-all li {
	width: 160px;
	height: 32px;
	background: #b5b5b5;
	font-size: 12px;
	text-align: center;
	line-height: 32px;
}
.footer .coop-all li a {
	display: block;
	width: 145px;
	height: 32px;
	color: #333;
}
.footer .coop-all li:hover {
	font-size: 14px;
	background: #939393;
}
.footer .coop-all li a:hover {
	cursor: pointer;
}
.o-rota {
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	-webkit-transform: rotateZ(180deg);
	-moz-transform: rotateZ(180deg);
	-ms-transform: rotateZ(180deg);
	-o-transform: rotateZ(180deg);
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.synopsis-wrap {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
}
.synopsis-wrap .synopsis-box {
	position: absolute;
	top: 15%;
	left: 50%;
	width: 1000px;
	margin-left: -25%;
	padding: 50px 0 20px;
	background: url("../images/synopsis_box.png") no-repeat;
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
	z-index: 9999;
}
.synopsis-wrap .synopsis-box .close {
	position: absolute;
	top: 0;
	right: 0;
	width: 60px;
	height: 60px;
	margin: 23px 23px 0 0;
	background: #000;
}
.synopsis-wrap .synopsis-box .close img {
	width: 39px;
	height: 37px;
	margin: 12px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.synopsis-wrap .synopsis-box .close:hover img, .video-fixed span img:hover {
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.synopsis-wrap .synopsis-box h2 {
	width: 309px;
	height: 24px;
	margin: 0 0 36px 56px;
}
.synopsis-wrap .synopsis-box h2 img {
	width: 309px;
	height: 24px;
}
.synopsis-wrap .synopsis-box h1 {
	width: 500px;
	margin: 0 auto 20px;
	color: #282828;
	font-size: 25px;
	text-align: center;
}
.synopsis-wrap .synopsis-box div {
	width: 820px;
	height: auto;
	margin: 0 auto 48px;
}
.synopsis-wrap .synopsis-box div p {
	width: 820px;
	font-size: 14px;
	line-height: 28px;
}
.video-fixed {
	display: none;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10001;
}
.video-fixed div {
	position: relative;
	width: 800px;
	height: 600px;
	margin: 200px auto 0;
	background: url("../images/video_shadow.png");
	background-size: 100% 100%;
	-webkit-background-size: 100% 100%;
	-moz-background-size: 100% 100%;
	-ms-background-size: 100% 100%;
	-o-background-size: 100% 100%;
}
.video-fixed div video {
	position: absolute;
	top: 27px;
	left: 15px;
	width: 780px;
	height: 540px;
	margin: 0 auto;
}
.video-fixed span {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 60px;
	height: 60px;
	background: #000;
}
.video-fixed span img {
	width: 39px;
	height: 39px;
	margin: 10px;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}
.other {
    position: fixed;
    top: 1000px;
    right: 5%;
    width: 138px;
	height: 394px;
    z-index: 9999;
    background: url("../images/rocket.png") no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
    -webkit-animation: Beat3 4s ease-in-out infinite;
    -moz-animation: Beat3 4s ease-in-out infinite;
    -o-animation: Beat3 4s ease-in-out infinite;
    animation: Beat3 4s ease-in-out infinite;
}
.other .scrollTop {
	display: block;
	width: 138px;
	height: 155px;
}
.other a {
    float: left;
	width: 138px;    
}
.qq-a {
	height: 64px;
}
.forum-a {
	height: 50px;
}
.weibo-a {
    height: 55px;
}
.weix {
	height: 68px;
}
.other img {
	display: none;
	position: absolute;
	bottom: 25px;
	left: -140px;
	width: 138px;
	height: 138px;
}
.scale-small {
    -webkit-animation: scaleA 0.5s ease-in-out;
    -moz-animation: scaleA 0.5s ease-in-out;
    -ms-animation: scaleA 0.5s ease-in-out;
    -o-animation: scaleA 0.5s ease-in-out;
    animation: scaleA 0.5s ease-in-out;
}
@-webkit-keyframes'scaleA' {
    0 {-webkit-transform: scale(1); 
        -moz-transform: scale(1); 
        -ms-transform: scale(1); 
        -o-transform: scale(1); 
        transform: scale(1); }
    100% {-webkit-transform: scale(0) rotate(180deg); 
        -moz-transform: scale(0) rotate(180deg); 
        -ms-transform: scale(0) rotate(180deg); 
        -o-transform: scale(0) rotate(180deg); 
        transform: scale(0) rotate(180deg);}
}
/*内页*/
.inside-wrap {
	width: 100%;
	min-width: 1200px;
	background: url("../images/inside_bg.jpg") no-repeat center;
}
.inside .banner {
	position: relative;
	width: 1200px;
	height: 492px;
	padding-top: 0px;
	margin-top: 20px;
}
.inside .banner img {
	position: absolute;
}
.inside .banner .moon {
	top: -400px;
	left: 240px;
}
.inside .banner .logo {
	top: 47px;
}
.inside .banner .i-role-01 {
	bottom: -69px;
	left: 444px;
	width: 263px;
	height: 440px;
	z-index: 9992;
	-webkit-animation: Beat 1.9s ease-in-out infinite;
	-moz-animation: Beat 1.9s ease-in-out infinite;
	-o-animation: Beat 1.9s ease-in-out infinite;
	animation: Beat 1.9s ease-in-out infinite;
}
.inside .banner .i-role-02 {
	bottom: -47px;
	left: 254px;
	width: 199px;
	height: 365px;
	z-index: 9992;
	-webkit-animation: Beat 2.6s ease-in-out infinite;
	-moz-animation: Beat 2.6s ease-in-out infinite;
	-o-animation: Beat 2.6s ease-in-out infinite;
	animation: Beat 2.6s ease-in-out infinite;
}
.inside .banner .i-role-03 {
	bottom: -30px;
	left: 674px;
	width: 246px;
	height: 340px;
	z-index: 9992;
	-webkit-animation: Beat 2.2s ease-in-out infinite;
	-moz-animation: Beat 2.2s ease-in-out infinite;
	-o-animation: Beat 2.2s ease-in-out infinite;
	animation: Beat 2.2s ease-in-out infinite;
}
.inside .banner .i-role-04 {
	bottom: -53px;
	left: 64px;
	width: 200px;
	height: 296px;
	z-index: 9992;
	-webkit-animation: Beat 2.8s ease-in-out infinite;
	-moz-animation: Beat 2.8s ease-in-out infinite;
	-o-animation: Beat 2.8s ease-in-out infinite;
	animation: Beat 2.8s ease-in-out infinite;
}
.inside .banner .i-role-05 {
	bottom: -14px;
	left: 907px;
	width: 330px;
	height: 286px;
	z-index: 9992;
	-webkit-animation: Beat 2.4s ease-in-out infinite;
	-moz-animation: Beat 2.4s ease-in-out infinite;
	-o-animation: Beat 2.4s ease-in-out infinite;
	animation: Beat 2.4s ease-in-out infinite;
}
.inside .main {
	position: relative;
	width: 1200px;
	height: 894px;
	z-index: 9993;
}
.inside .main .sidebar {
	float: left;
	width: 264px;
	height: 209px;
	padding-top: 287px;
	background: url("../images/sidebar_bg5.png") no-repeat;
	background-size: 100% 100%;
}
.inside .main .sidebar a {
	display: block;
	width: 188px;
	height: 47px;
	margin: 0 auto;
	background: #fff;
	opacity: 0.1;
	filter:alpha(opacity=10);
}
.inside .main .sidebar a:hover {
	opacity: 0;
	filter:alpha(opacity=0);
}
.inside .main .column {
	position: relative;
	float: right;
	width: 926px;
	height: 705px;
	margin-top: -45px;
	padding-top: 140px;
	background: url("../images/column_bg.png") no-repeat;
	background-size: 100% 100%;
}
.inside .main .column .column-box {
	width: 763px;
	height: 657px;
	margin: 0 auto;
}
.main .inside-column h2 {
	position: absolute;
	top: 28px;
	right: 97px;
	width: 185px;
	height: 63px;
	background: url("../images/font_i.png") no-repeat 0 -64px;
}
.main .data-column h2 {
	position: absolute;
	top: 28px;
	right: 97px;
	width: 185px;
	height: 63px;
	background: url("../images/font_i.png") no-repeat 0 -128px;
}
.main .video-column h2 {
	position: absolute;
	top: 28px;
	right: 97px;
	width: 185px;
	height: 63px;
	background: url("../images/font_i.png") no-repeat 0 0;
}
.main .column-box p {
	overflow: hidden;
	width: 763px;
	height: 29px;
	border-bottom: 1px dashed #000;
	color: #3a3a3a;
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.main .inside-column .checkpoint-box {
	width: 790px;
	height: 622px;
	padding-top: 33px;
}
.main .inside-column .checkpoint-box .page {
	width: 790px;
	height: 570px;
}
.main .inside-column .checkpoint-box .page li {
	position: relative;
	float: left;
	width: 239px;
	height: 244px;
	margin: 0 21px 34px 0;
}
.page .mus-01 {
	background: url("../images/checkpoint_11.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-02 {
	background: url("../images/checkpoint_10.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-03 {
	background: url("../images/checkpoint_12.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-04 {
	background: url("../images/checkpoint_08.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-05 {
	background: url("../images/checkpoint_05.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-06 {
	background: url("../images/checkpoint_06.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-07 {
	background: url("../images/checkpoint_07.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-08 {
	background: url("../images/checkpoint_09.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-09 {
	background: url("../images/checkpoint_13.png") no-repeat;
	background-size: 100% 100%;
}
.page .mus-10 {
	background: url("../images/checkpoint_14.png") no-repeat;
	background-size: 100% 100%;
}
.checkpoint-box .page li a {
	position: absolute;
	top: 197px;
    right: 47px;
	display: block;
	width: 31px;
	height: 31px;
	background: url("../images/voice_01.png") no-repeat;
	background-size: 100% 100%;
}
.checkpoint-box .page li .voice {
	background: url("../images/voice_02.png") no-repeat;
	background-size: 100% 100%;
}
.checkpoint-box .page .mus-06 a {
	right: 17px;
}
.checkpoint-box .page .mus-09 a {
	right: 25px;
}
.simplePagerNav {
    display: block;
    width: 100%;
    margin-bottom: 2.5rem;
    text-align: center;
}
.simplePagerNav li {
    display: inline-block;
    width: 25px;
    height: 29px;
    margin: 5px;
    box-shadow: 0 0 1px 1px #9e9d9e;
    text-align: center;
    line-height: 29px;
}
.simplePagerNav a {
	display: block;
	color: #000;
}
.simplePagerNav .currentPage {
	background: #000;
}
.simplePagerNav .currentPage a {
	color: #fff;
}
.data-column .page {
	min-height: 440px;
	margin-bottom: 140px;
}
.data-column .page li {
	width: 763px;
	height: 43px;
	border-bottom: 1px dashed #d9d9d9;
	font-size: 16px;
}
.data-column .page li:hover {
	border-bottom: 1px solid #d9d9d9;
}
.data-column .page li p {
	overflow: hidden;
	float: left;
	width: 660px;
	height: 43px;
	border-bottom: none;
	line-height: 43px;
	color: #010101;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.data-column .page li span {
	float: right;
	color: #010101;
	font-size: 16px;
	line-height: 43px;
}


.data-column .details-box {
	overflow: auto;
	width: 780px;
	height: 515px;
	padding-top: 45px;
}
.data-column .details-box h1 {
	width: 763px;
	margin-bottom: 31px;
	font-size: 36px;
	font-weight: bold;
	text-align: center;
}
.data-column .details-box p {
	overflow: visible;
	height: auto;
	border: none;
	font-size: 15px;
	line-height: 25px;
	white-space: normal; 
	text-overflow: ellipsis; 
}
.data-column .details-box h3 {
	margin: 24px 0;
	font-size: 24px;
}

.video-box {
	width: 763px;
	height: 500px;
	padding-top: 39px;
}
.video-box .video-div {
	position: relative;
	width: 577px;
	height: 400px;
	margin: 0 auto 40px;
	padding-top: 10px;
	background: url("../images/video_shadow.png") no-repeat;
	background-size: 100% 100%;
}
.video-box .video-div video {
	width: 577px;
	height: 320px;
}
.video-box .video-div span {
	position: absolute;
    top: 8px;
    left: 0;
	width: 577px;
	height: 400px;
    background: rgba(0, 0, 0, 1) url("../images/video_img.png") no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
}
.video-box .video-div i {
    position: absolute;
    top: 155px;
    left: 230px;
    width: 109px;
    height: 109px;
    background: url("../images/video_play.png") no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -ms-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -webkit-animation: Beat2 2.1s ease-in-out infinite;
    -moz-animation: Beat2 2.1s ease-in-out infinite;
    -ms-animation: Beat2 2.1s ease-in-out infinite;
    -o-animation: Beat2 2.1s ease-in-out infinite;
    animation: Beat2 2.1s ease-in-out infinite;
}
.picScroll-left {
	width: 800px;
	/*width: 300px;*/
	margin: 0 auto;
	overflow:hidden;
	position:relative;
}
.tempWrap {
	margin: 0 auto;
}
.picScroll-left .hd .prev,.picScroll-left .hd .next {
	position: absolute;
	top: 20px;
	display:block;
	width: 26px;
	height: 50px;
	overflow:hidden;
	cursor:pointer;
}
.picScroll-left .hd .prev {
	left: 0;
	background: url("../images/left_a.png") no-repeat;
	background-size: 100% 100%;
}
.picScroll-left .hd .next {
	right: 0;
	background: url("../images/right_a.png") no-repeat;
	background-size: 100% 100%;
}
.picScroll-left .bd ul li{ 
	margin:0 8px;
	float:left;
	_display:inline;
	overflow:hidden;
	text-align:center;
}
.picScroll-left .bd ul li .pic img{
	width:120px;
	height:90px;
	display:block;
	padding:2px;
	cursor: pointer;
}
.picScroll-left .bd ul li span {
	display: block;
	overflow: hidden;
	width: 120px;
	height: 38px;
	margin-top: 5px;
	font-size: 14px;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background-color: #ccc;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #000000;
}
/*公共导航*/
.nav {
  position: relative;
  z-index: 9999;
}
.nav .nav-top {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 30;
  border-bottom: 1px solid #463d3c;
  height: 79px;
  background: #010304;
  font-size: 12px;
}
.nav .nav-top .logo {
  width: 150px;
  height: 37px;
  margin: 20px 85px 0 10px;
}
.nav .nav-top .logo img {
  display: block;
  width: 100%;
  height: 100%;
}
.nav .nav-top .cont {
  position: relative;
  float: right;
}
.nav .nav-top .cont ul {
  height: 79px;
  line-height: 79px;
}
.nav .nav-top .cont ul li {
  float: left;
  width: 100px;
  margin-right: 20px;
  text-align: center;
}
.nav .nav-top .cont ul li a {
  color: #e1e1e1;
  display: block;
  font-size: 14px;
}
.nav .nav-top .cont ul li a:hover {
  color: #ffffff;
}
.nav .nav-top .active a {
  color: #ffffff;
}
.nav .nav-top i {
  position: absolute;
  width: 100px;
  height: 3px;
  background: #12a7eb;
  bottom: 0;
  left: 0;
}
.nav .nav-move {
  display: none;
}
.nav .nav-more {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 29;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  font-size: 12px;
}
.nav .nav-more .cont {
  padding: 80px 0 0 221px;
}
.nav .nav-more .cont a {
  color: #e0e0e0;
}
.nav .nav-more .cont i {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 6px;
}
.nav .nav-more .cont i .hot,
.nav .nav-more .cont i .new,
.nav .nav-more .cont i .test {
  background: url(../images/sprite_img.png) -42px -94px no-repeat;
}
.nav .nav-more .cont i .new {
  background-position: -57px -94px;
}
.nav .nav-more .cont i .test {
  background-position: -88px -94px;
}
.nav .nav-more .cont ul li {
  height: 38px;
  line-height: 38px;
  text-align: center;
}
.nav .nav-more .cont ul li a:hover {
  color: #ffffff;
}
.nav .nav-more .cont .ul_com {
  float: left;
  width: 100px;
  margin-right: 20px;
  padding-top: 1px;
  padding-bottom: 26px;
}
.nav .nav-more .cont .ul_com_game {
  width: auto;
  margin-left: 14px;
}
.nav .nav-more .cont .ul_com_game ul {
  float: left;
  width: auto;
  margin-right: 16px;
}
.nav .nav-more .cont .ul_com_game ul li {
  text-align: left;
}
.center {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.footer_wrap {
  position: relative;
  bottom: 0;
  width: 100%;
  min-width: 1200px;
  height: 155px;
  padding-top: 32px;
  background: #000;
  z-index: 9998;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.footer_wrap .footer_box_mobile {
  display: none;
}
.footer_wrap .footer_box {
  width: 1200px;
  margin: 0 auto;
}
.footer_wrap .footer_box dl {
  display: block;
  width: 750px;
  margin: 0 auto;
}
.footer_wrap .footer_box dl dt,
.footer_wrap .footer_box dl img {
  float: left;
}
.footer_wrap .footer_box dl dt {
  margin-top: 34px;
}
.footer_wrap .footer_box dl dd {
  float: right;
  width: 740px;
  color: #fff;
  font-size: 12px;
  text-align: left;
}
.footer_wrap .footer_box dl dd p {
  overflow: hidden;
  bottom: 0;
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.footer_wrap .footer_box dl dd p a {
  margin: 0px 7px;
  color: #fff;
}
.footer_wrap .footer_box dl dd p a:hover {
  color: #fff;
  cursor: pointer;
}
.footer_wrap .cooperation {
  float: left;
  width: 210px;
  height: 65px;
  padding-top: 34px;
  margin: -9px 0 0 46px;
}
.footer_wrap .cooperation .coop-box {
  position: relative;
  width: 172px;
  height: 29px;
  padding-top: 3px;
  background: #b5b5b5;
  color: #333;
  font-size: 12px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.footer_wrap .cooperation .coop-box:hover {
  background: #fff;
  cursor: pointer;
}
.footer_wrap .cooperation .coop-box span {
  float: left;
  width: 127px;
  border-right: 1px solid #8e8e8e;
  line-height: 27px;
  text-align: center;
  padding-right: 10px;
}
.footer_wrap .cooperation .coop-box img {
  width: 12px;
  height: 8px;
  margin: 10px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}